<template>
  <div class="title">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    msg: String
  }
}
</script>

<style scoped>
h1 {
  font-size: 100px;
  letter-spacing: 2px;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.title {
  margin-top: 200px;
}
</style>
