<template>
  <div class="home container-fluid">
    <div class="row">
      <div class="col-12">
        <Title msg="luehrs.info"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="hinweis">Under Construction...</p>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Title.vue'
import anime from 'animejs'

export default {
  name: 'Home',
  components: {
    Title
  },
  mounted () {
    const textWrapper = document.querySelector('.hinweis')
    textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, '<span class="buchstabe">$&</span>')
    anime.timeline({
      loop: true
    })
      .add({
        targets: '.hinweis .buchstabe',
        translateX: [40, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: 'easeOutExpo',
        duration: 1200,
        delay: (el, i) => 500 + 30 * i
      }).add({
        targets: '.hinweis .buchstabe',
        translateX: [0, -30],
        opacity: [1, 0],
        easing: 'easeInExpo',
        duration: 1100,
        delay: (el, i) => 100 + 30 * i
      })
  }
}
</script>

<style scoped>
.hinweis {
  font-family: 'Roboto', sans-serif;
  font-size: 1.8em;
  letter-spacing: 0.5em;
  text-transform: uppercase;
}

.buchstabe {
  display: inline-block;
  line-height: 1em;
}
</style>
